.ButtonBlock {
  height: 50px;
  max-width: 200px;
  width: 100%;
  background: #0da6c8;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 22px;
  color: #ffffff;
  transition: 0.3s;
  margin-top: 40px;
  font-weight: 300;
}

.ButtonBlock:hover {
  background-color: #ffffff;
  color: #0da6c8;
  border: 1px solid #0da6c8;
}

.ButtonBlockSmall {
  height: 50px;
  max-width: 200px;
  width: 100%;
  background: #0da6c8;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  transition: 0.3s;
  margin-top: 40px;
  font-weight: 300;
}

.ButtonBlockSmall:hover {
  background-color: #ffffff;
  color: #0da6c8;
  border: 1px solid #0da6c8;
}

.ButtonBlockDisabled {
  opacity: 0.5;
}

.ButtonBlockDisabled:hover {
  background: #0da6c8;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.InputBlock {
  height: 50px;
  max-width: 500px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ffffff;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 22px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: 300;
}

.InputBlock:last-of-type {
  margin-bottom: 0px;
}

.InputBlock::placeholder {
  color: #000000 !important;
  font-size: 20px;
  font-weight: 300;
}

.FormBlocks {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  width: 100%;
  max-width: 530px;
}

.InputBlock:focus {
  outline: none;

}

.ContentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px 0;
}

.InnerBlock {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ImgBlock {
  height: 140px;
  width: 220px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
