.dropdown {
  position: relative;
}
.dropdown:focus {
  outline: none;
}
.dropdown-content {
  width: 60px;
  border-radius: 8px;
  border: 1px solid #0da6c8;
  color: #0da6c8;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  position: absolute;
  transition: 0.3s;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.dropdown-content.active {
  opacity: 1;
}
.dropdown-link-place {
  width: 100%;
  height: 55px;
  border: none;
  background-color: #fff;
  border-bottom: 1px solid #0da6c8;
  font-size: 22px;
  font-weight: lighter;
  cursor: pointer;
}
.dropdown-link:hover,
.dropdown-link:active {
  color: #fff;
}
.dropdown-link-place:first-of-type {
  border-radius: 8px 8px 0px 0px;
}
.dropdown-link-place:last-of-type {
  border-bottom: none;
  border-radius: 0px 0px 8px 8px;
}
.dropdown-link-place:focus {
  outline: none;
}
.dropdown-link-place:hover {
  cursor: pointer;
  background-color: #0da6c8;
  color: #fff;
}
.show {
  display: block;
}
.lang-list:focus {
  outline: none;
}


