.Card {
  background-color: #0da6c8;
}

.FormTitle {
  font-size: 28px;
  color: #ffffff;
  font-weight: 200;
  margin-bottom: 35px;
}


.FormSubTitle {
  font-size: 22px;
  color: #ffffff;
  font-weight: 200;
  margin-bottom: 25px;
}

.FormText {
  font-size: 18px !important;
  color: #ffffff;
  font-weight: 200;
}
.FormTextDark {
  font-size: 18px !important;
  color: #000000;
  font-weight: 200;
}

.SmallInputText {
  font-size: 16px;
  color: #000;
  font-weight: 200;
}

.NoElementsText {
  font-size: 20px;
  color: #ffffff;
  font-weight: 200;
  margin-left: 20px;

}

.FormBlock {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.FormBlockElement {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.FormBlockElement:last-of-type {
  margin-bottom: 0;
}
.Content-module {
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
}

.ElementButton {
  height: 50px;
  max-width: 220px;
  width: 100%;
  background-color: #0da6c8;
  color: #ffffff;
  font-size: 20px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  transition: 0.3s;
  margin-left: 15px;
  font-weight: 100;
}

.ElementButton:hover {
  background-color: #ffffff;
  color: #0da6c8;
}

.FormInput {
  height: 50px;
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 22px;
  color: #000000;
  padding: 20px;
  border: none;
  font-weight: 200;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.FormInput::placeholder {
  color: #000000;
  font-size: 22px;
  font-weight: 200;
}

.FormInput:focus {
  outline: none;
  font-size: 22px;
}

.FormInputSmall {
  height: 50px;
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  color: #000000;
  padding: 20px;
  border: none;
  font-weight: 200;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.FormInputSmall::placeholder {
  color: #000000;
  font-size: 18px;
  font-weight: 200;
}

.CardBlock {
  border: 1px solid #ffffff;
  border-top: none;
  border-radius: 12px;
  padding-bottom: 15px;
  margin: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.TabInputBlock {
  display: flex;
  margin: 15px 0;
  padding-left: 15px;
}

.Table {
  border: 0;
  padding: 0 15px;
  width: 100%;
  display: table;
}

.TableBlock {
  width: 100%;
}

.TableFirstLineBlock {
  border-bottom: 1px solid #ffffff;
  text-align: center;
  font-size: 18px;
  height: 30px;
  color: #ffffff;
  font-weight: 200;
}

.TableFirstLineBlock:first-of-type {
  padding: 0 10px;
}

.TableSecondLineBlock {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  height: 70px;
  padding: 0 10px;
}

.TableSecondLineBlock:last-of-type {
  border-right: 0;
}

.TableSecondLineForm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TableFormBlock {
  border: 1px solid #ffffff;
  background-color: #0da6c8;
  border-radius: 10px;
  padding: 2px 5px;
  color: #ffffff;
}

.TableFormBlock:focus {
  outline: none;
}

.TableSecondLineText {
  color: #ffffff;
  font-size: 20px;
  font-weight: 200;
}

.TableSecondLineImg {
  max-width: 16px;
  margin: 0 auto;
}

.TabBlockImg {
  height: 25px;
}

.CardBlockHeder {
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;

}

.CardBlockHederTab {
  width: 100%;
  max-width: 100px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
}

.CardBlockHederTab:focus {
  border-color: #0da6c8 !important;
}

.CardBlockHederTab:active {
  border-color: #0da6c8 !important;
}

.cardblockhedertab:hover {
  border-color: #0da6c8 !important;
}

.CardBlockHederTab:first-of-type {
  margin-left: 15px;
}

.CardBlockHederTabTresh {
  margin-left: auto;
  border-radius: 0 !important;
}

.CardBlockHederTabTresh:focus {
  border: 1px solid #0da6c8 !important;
}

a.CardBlockHederTabTextColor {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 126px;
  padding: 10px 0 !important;
  color: #000000 !important ;
  font-size: 15px !important;
}

.CardBlockHederTabArrows {
  display: flex !important;
  align-items: center;
  width: 100%;
  max-width: 40px;
  justify-content: center;
  padding: 10px 0 !important;
  margin-right: 0px  !important;
  color: #000000 !important ;
  font-size: 20px !important;
}
.CardBlockHederTabName {
  display: flex !important;
  align-items: center;
  width: 100%;
  max-width: 350px;
  justify-content: flex-start;
  padding: 10px 0 !important;
  color: #000000 !important ;
  font-size: 20px !important;
  font-weight: 300;
}

.CardBlockHederTabNameWide {
  display: flex !important;
  align-items: center;
  width: 100%;

  justify-content: flex-start;
  padding: 10px 0 !important;
  color: #000000 !important ;
  font-size: 20px !important;
  font-weight: 300;
}

.CardBlockHederTabSave{
  display: flex !important;
  align-items: center;
  width: 100%;
  max-width: 50px;
  max-height: 35px;
  justify-content: center;
  padding: 10px 0 !important;
  margin-right: 20px  !important;
  color: #000000 !important ;
  font-size: 20px !important;
}

.CardBlockHederTabLine {
  background-color: #0da6c8 !important;
  height: 34px !important;
  width: 1px !important;
  padding: 0 !important;
  margin: 7px 0;
  border: 0 !important;
}

.TableSecondLineBlockTrash {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CenterContent {
  align-items: center;
  justify-content: center;
}

.TableSecondLineInput {
  max-width: 200px;
  font-weight: 200;
  font-size: 20px;
}

.TableSecondLineInput:focus {
  border: 1px solid #ffffff;
}

.QuizLine {
  display: block;
  align-items: center;
  padding: 0 20px;
}

span.FileUploadText {
  margin-left: 10px !important;
}

.Video360Block {
  margin-bottom: 0 !important;
}

.InputBorder {
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 2px 5px;
}

span.InputText {
  color: #ffffff !important;
}

.CardBlockHederImg {
  height: 25px;
  align-items: center;
  justify-content: center;
}


.CardBlockHederImgSmall {
  height: 18px;
  align-items: center;
  justify-content: center;
}

.QuestionHeader {
  margin-left: 15px;
width: 100%;
}


.BoxShadowSelect > button,
.BoxShadowSelect.show > button {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}

.ListItemStyled {
  background-color: transparent !important;
  font-weight: 200;
}