#root {
  width: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  background-color: #0da6c8 !important;
  padding: 20px 20px 20px 20px !important;
  margin: 0 auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
.container {
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 1315px !important;
}
