.loader {
  /* position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%); */
      height: 100%;
        width: 100%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        /* dark color here*/
        /* background-color: rgb(0,0,0);
        background-color: rgba(0,0,0, 0.9); */
}
