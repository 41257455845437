.header-wrapper {
  display: block;
  max-width: 1820px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  max-width: 1820px;
  width: 100%;
  border-radius: 12px;
  padding: 15px;
  align-items: center;
  background-color: #fff;
  margin-bottom: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.header-navigation-button {
  outline: none;
  border: none;
  background: #fff;
  color: #000;
  margin-right: 40px;
}
.header-navigation-button:focus {
  outline: none;
}
.header-navigation-link {
  color: #000;
  font-size: 22px;
  font-weight: lighter;
}
.header-navigation-link:active {
  color: #0da6c8;
}
.header-navigation-link:hover {
  color: #0da6c8;
}

.currentSection {
  text-decoration: underline;
}

.header-logo {
  height: 60px;
}
.logoutIcon {
  height: 25px;
  align-items: center;
}

.lang-list {
  width: 65px;
  height: 55px;
  border-radius: 12px;
  border: 1px solid #0da6c8 !important;
  outline: none;
  background-color: #fff;
  color: #0da6c8 !important;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: lighter;
}
.lang-list:active {
  outline: none !important;
}
.lang-triengl {
  border: 5px solid transparent;
  border-top: 6px solid #0da6c8;
  margin-top: 15px;
}
.lang-list-text {
  font-size: 22px;
  margin-right: 8px;
}
.settings-and-login {
  display: flex;
  align-items: center;
}
.settings {
  margin-right: 10px;
}
.settings-link {
  font-size: 28px;
  color: #000;
  font-weight: lighter;
}

.settings-link:hover {
  color: #0da6c8 !important;
}
.log-out {
  background-color: transparent;
  border: none;
  font-size: 28px;
  font-weight: lighter;
  margin-right: 10px;
}
.log-out:hover {
  color: #0da6c8;
  text-decoration: underline;
}
.log-out:focus {
  outline: none;
}
