.container {
  margin: 0 !important;
  padding: 0 !important;
  max-width: 1820px !important;
}
.Statistic-info-line-section {
  display: flex;
  margin-bottom: 50px;
  border: 1px solid #fff;
  border-radius: 12px;
}
.all-users-title-block {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 0px 0px 12px;
  height: 220px;
  width: 45px;
  border-top: #fff 1px solid;
}
.all-users-title {
  transform: rotate(270deg);
  font-size: 28 pt;
  color: #0da6c8;
  font-weight: bold;
  white-space: nowrap;
}
.all-users-block {
  display: flex;
}
.all-users-content {
  background-color: transparent;
  height: 220px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.first-content-circle {
  width: 162px;
  height: 162px;
  border: 10px #4dbdd6 solid;
  border-radius: 50%;
  margin-right: 10px;
}
.data-circle {
  width: 100%;
  height: 100%;
  border: 10px #fff solid;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.data-circle-b {
  width: 100%;
  height: 100%;
  border: 10px #fff solid;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.gender {
  display: flex;
}
.gender-title-block {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 0px 0px 12px;
  height: 220px;
  width: 45px;
}
.gender-title {
  transform: rotate(270deg);
  font-size: 28 pt;
  color: #0da6c8;
  font-weight: bold;
}
.first-gender-line-image {
  width: 30px;
}

.gender-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.first-gender-line-statistic {
  background-color: #4dbdd6;
  width: 200px;
  height: 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.first-gender-line-statistic-small {
  background-color: #fff;
  height: 10px;
  border-radius: 6px;
  margin-left: 4px;
  margin-right: 4px;
}
.first-gender-line-image-place {
  margin-right: 10px;
}
.first-gender-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.first-gender-line:last-of-type {
  margin-bottom: 0px;
}
.download-data {
  background-color: #fff;
  width: 170px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.download-data-image {
  width: 50px;
  margin-bottom: 40px;
  margin-top: 70px;
}
.download-data-title {
  background-color: #fff;
  color: #0da6c8;
  white-space: nowrap;
  font-size: 9pt;
  font-weight: bold;
  border: none;
  outline: none;
}
.completed-icon {
  width: 25px;
}
.completed-title {
  font-size: 10pt;
  color: #fff;
}
.completed-persents {
  font-size: 14pt;
  color: #fff;
}
.in-progress-icon {
  width: 40px;
  margin-bottom: 10px;
}
.ages-title {
  font-size: 22pt;
  color: #fff;
}
.ages-subtitle {
  font-size: 12pt;
  color: #fff;
  margin-top: -10px;
}

